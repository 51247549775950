import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import {
  showErrorMsg,
  showSuccessMsg,
  validateAnyFormField,
} from "../../../helpers/ValidationHelper";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import { useSelector, useDispatch } from "react-redux";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";
import { reduxStore } from "../../../stateManagment/reduxStore";
import { Helmet } from "react-helmet";
import {
  GetLocalizationControlsJsonDataForScreen,
  getLanguageCodeFromSession,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  const handleContactUsForm = async (event) => {
    event.preventDefault();

    try {
      //--start loader
      dispatch(rootAction.commonAction.setLoading(true));

      let isValid = false;
      let validationArray = [];

      isValid = validateAnyFormField("Name", FullName, "text", null, 200, true);
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      isValid = validateAnyFormField("Email", Email, "email", null, 100, true);
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      isValid = validateAnyFormField(
        "Phone Number",
        PhoneNumber,
        "text",
        null,
        20,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      isValid = validateAnyFormField(
        "Subject",
        Subject,
        "text",
        null,
        150,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      isValid = validateAnyFormField(
        "Message",
        Message,
        "text",
        null,
        2000,
        true
      );
      if (isValid == false) {
        validationArray.push({
          isValid: isValid,
        });
      }

      //--check if any field is not valid
      if (validationArray != null && validationArray.length > 0) {
        isValid = false;
        return false;
      } else {
        isValid = true;
      }

      if (isValid) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        const param = {
          requestParameters: {
            FullName: FullName,
            Email: Email,
            PhoneNumber: PhoneNumber,
            Subject: Subject,
            Message: Message,
          },
        };

        //--make api call for data operation
        const response = await MakeApiCallAsync(
          Config.END_POINT_NAMES["CONTACT_US"],
          null,
          param,
          headers,
          "POST",
          true
        );
        if (response != null && response.data != null) {
          let userData = JSON.parse(response.data.data);
          if (
            userData.length > 0 &&
            userData[0].ResponseMsg != undefined &&
            userData[0].ResponseMsg == "Saved Successfully"
          ) {
            showSuccessMsg("Message sent successfully!");

            //--Empty form fields
            setFullName("");
            setEmail("");
            setPhoneNumber("");
            setSubject("");
            setMessage("");
          } else {
            showErrorMsg("An error occured. Please try again!");
            return false;
          }
        }
      }
    } catch (err) {
      console.log(err);
      showErrorMsg("An error occured. Please try again!");

      return false;
    } finally {
      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Index_Contact_Us"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Contact Us</title>
        <meta name="description" content={siteTitle + " - Contact Us"} />
        <meta name="keywords" content="Contact Us"></meta>
      </Helmet>

      <SiteBreadcrumb lblKey="lbl_breadcrumb_contact" title="Contact Us" />

      <div className="theme-light">
        <section className="contact-area ptb-60">
          <div className="container">
            <div className="section-title">
              <h2 className="main-title">
                <span id="lbl_contactus_title">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Contact Us",
                        "lbl_contactus_title"
                      )
                    : "Contact Us"}
                </span>
              </h2>
            </div>

            <div className="row align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="contact-form">
                  <h3 id="lbl_cont_drop_us">
                    {" "}
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Drop Us A Line",
                          "lbl_cont_drop_us"
                        )
                      : "Drop Us A Line"}
                  </h3>
                  <p>
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "We’re happy to answer any questions you have or provide you with an estimate. Just send us a message in the form below with any questions you may have.",
                          "lbl_drop_desc"
                        )
                      : "We’re happy to answer any questions you have or provide you with an estimate. Just send us a message in the form below with any questions you may have."}
                  </p>

                  <form id="contactForm" onSubmit={handleContactUsForm}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label id="lbl_cont_form_name">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Name",
                                  "lbl_cont_form_name"
                                )
                              : "Name"}
                            <span className="required-field">*</span>
                          </label>
                          <input
                            type="text"
                            name="FullName"
                            id="FullName"
                            className="form-control"
                            required={true}
                            data-error="Please enter your name"
                            placeholder={
                              LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Enter your name",
                                    "lbl_cont_form_name"
                                  )
                                : "Enter your name"
                            }
                            value={FullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label id="lbl_cont_form_email">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Email",
                                  "lbl_cont_form_email"
                                )
                              : "Email"}
                            <span className="required-field">*</span>
                          </label>
                          <input
                            type="email"
                            name="Email"
                            id="Email"
                            className="form-control"
                            required={true}
                            data-error="Please enter your email"
                            placeholder={
                              LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Enter your Email Address",
                                    "lbl_cont_form_email"
                                  )
                                : "Enter your Email Address"
                            }
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <label id="lbl_cont_form_phon">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Phone Number",
                                  "lbl_cont_form_phon"
                                )
                              : "Phone Number"}
                            <span className="required-field">*</span>
                          </label>
                          <input
                            type="text"
                            name="PhoneNumber"
                            id="PhoneNumber"
                            className="form-control"
                            required={true}
                            data-error="Please enter your phone number"
                            placeholder={
                              LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Enter your Phone Number",
                                    "lbl_cont_form_phon"
                                  )
                                : "Enter your Phone Number"
                            }
                            value={PhoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <label id="lbl_cont_form_subj">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Subject",
                                  "lbl_cont_form_subj"
                                )
                              : "Subject"}
                            <span className="required-field">*</span>
                          </label>
                          <input
                            type="text"
                            name="Subject"
                            id="Subject"
                            className="form-control"
                            required={true}
                            data-error="Please enter subject"
                            placeholder={
                              LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Enter Subject",
                                    "lbl_cont_form_subj"
                                  )
                                : "Enter Subject"
                            }
                            value={Subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label id="lbl_cont_form_msg">
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "YOUR MESSAGE ",
                                  "lbl_cont_form_msg"
                                )
                              : "YOUR MESSAGE "}
                            <span className="required-field">*</span>
                          </label>
                          <textarea
                            name="Message"
                            id="message"
                            cols="30"
                            rows="8"
                            required={true}
                            data-error="Please enter your message"
                            className="form-control"
                            placeholder={
                              LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Enter your message",
                                    "lbl_cont_form_msg"
                                  )
                                : "Enter your message"
                            }
                            value={Message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          id="lbl_cont_btn_sndmsg"
                        >
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Send message",
                                "lbl_cont_btn_sndmsg"
                              )
                            : "Send message"}
                        </button>
                        <div
                          id="msgSubmit"
                          className="h3 text-center hidden"
                        ></div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-1 col-md-12"></div>
              <div className="col-lg-6 col-md-12">
                <div className="contact-info">
                  {/* lbl_here_to_help */}
                  <h3 id="lbl_contact_here_help">
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Here to Help",
                          "lbl_here_to_help"
                        )
                      : "Here to Help"}
                  </h3>
                  <p>
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Have a question? You may find an answer in our FAQs. But you can also contact us.",
                          "lbl_here_to_help_desc"
                        )
                      : "Have a question? You may find an answer in our FAQs. But you can also contact us."}
                  </p>
                  <ul className="contact-list">
                    <li>
                      <i className="fas fa-map-marker-alt"></i>
                      <span id="lbl_cont_location">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Location",
                              "lbl_cont_location"
                            )
                          : "Location"}
                        :
                      </span>{" "}
                      Doos Car Parts, INDUSTRIAL 3, SHARJAH, UAE
                    </li>
                    <li>
                      <i className="fas fa-phone"></i>
                      <span id="lbl_cont_callus">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Call Us",
                              "lbl_cont_callus"
                            )
                          : "Call Us"}
                        :
                      </span>{" "}
                      <a href="#">(+971) 52 113 8699</a>
                    </li>
                    <li>
                      <i className="far fa-envelope"></i>
                      <span id="lbl_cont_email">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Email Us",
                              "lbl_cont_form_email"
                            )
                          : "Email Us"}
                        :
                      </span>{" "}
                      <a href="#">info@dooscarparts.com</a>
                    </li>
                    <li>
                      <i className="fas fa-phone"></i>
                      <span id="lbl_cont_fax">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Phone No",
                              "lbl_cont_form_phon"
                            )
                          : "Phone No"}
                        :
                      </span>{" "}
                      <a href="#">(+971) 52 113 8699</a>
                    </li>
                  </ul>
                  {/* lbl_opening_hours */}
                  <h3>
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Opening Hours",
                          "lbl_opening_hours"
                        )
                      : "Opening Hours"}
                    :
                  </h3>
                  <ul className="opening-hours">
                    <li>
                      <span id="lbl_cont_mon">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Monday : ",
                              "lbl_cont_mon"
                            )
                          : "Monday : "}
                      </span>
                      <span>8AM - 6AM</span>
                    </li>
                    <li>
                      <span id="lbl_cont_tues">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Tuesday :",
                              "lbl_cont_tues"
                            )
                          : "Tuesday :"}
                      </span>{" "}
                      <span>8AM - 6AM</span>
                    </li>
                    <li>
                      <span id="lbl_cont_wed">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Wednesday :",
                              "lbl_cont_wed"
                            )
                          : "Wednesday :"}
                      </span>
                      <span>8AM - 6AM</span>
                    </li>
                    <li>
                      <span id="lbl_cont_thurs">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Thursday :",
                              "lbl_cont_thurs"
                            )
                          : "Thursday :"}
                        :
                      </span>{" "}
                      <span>8AM - 6AM</span>
                    </li>
                    <li>
                      <span id="lbl_cont_frid">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Friday :",
                              "lbl_cont_frid"
                            )
                          : "Friday :"}
                      </span>
                      <span>8AM - 6AM</span>
                    </li>
                    <li>
                      <span id="lbl_cont_sat">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Saturday :",
                              "lbl_cont_sat"
                            )
                          : "Saturday :"}
                      </span>{" "}
                      Closed
                    </li>
                    <li>
                      <span id="lbl_cont_sund">
                        {" "}
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Sunday :",
                              "lbl_cont_sund"
                            )
                          : "Sunday :"}
                      </span>{" "}
                      Closed
                    </li>
                  </ul>
                  <h3>
                    {" "}
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Follow Us",
                          "lbl_follow_us"
                        )
                      : "Follow Us"}
                    :
                  </h3>
                  <ul className="social">
                    <li>
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-behance"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-skype"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-pinterest-p"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="theme-light">
        <BestFacilities />
      </div>
    </>
  );
};

export default ContactUs;
