import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import HomeCategory1 from "../../resources/themeContent/images/category-product-image/pink-service.jpg";
import HomeCategory2 from "../../resources/themeContent/images/category-product-image/recovery.jpg";
import HomeCategory3 from "../../resources/themeContent/images/category-product-image/maintainace.jpg";
import HomeCategory4 from "../../resources/themeContent/images/category-product-image/car-test.jpg";
import HomeCategory5 from "../../resources/themeContent/images/category-product-image/garage.jpg";
import { GetLocalizationControlsJsonDataForScreen, getLanguageCodeFromSession, replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const HomeCategories = () => {

  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //-- Get website localization data
        let arryRespLocalization =
          await GetLocalizationControlsJsonDataForScreen(
            GlobalEnums.Entities["Index_Home_Page"],
            null
          );
        if (
          arryRespLocalization != null &&
          arryRespLocalization != undefined &&
          arryRespLocalization.length > 0
        ) {
          await setLocalizationLabelsArray(arryRespLocalization);
          console.log(arryRespLocalization, "<-- arryRespLocalization");
        }
      } catch (error) {
        console.log(error, "<-- error");
      }
    };

    fetchData();
  }, []);


  console.log(LocalizationLabelsArray, "<-- arryRespLocalization");

  // const categoryData = [
  //   {
  //     id: 1,
  //     index: 1,
  //     image: HomeCategory1,
  //     title: "Pink service for girls",
  //   },
  //   {
  //     id: 2,
  //     index: 2,
  //     image: HomeCategory2,
  //     title: "Recovery",
  //   },
  //   {
  //     id: 3,
  //     index: 3,
  //     image: HomeCategory3,
  //     title: "Annual Car maintainance membership",
  //   },
  //   {
  //     id: 4,
  //     index: 4,
  //     image: HomeCategory4,
  //     title: "Car test",
  //   },
  //   {
  //     id: 5,
  //     index: 5,
  //     image: HomeCategory5,
  //     title: "Garage",
  //   },
  // ];

  const categoryData = [
    {
      id: 1,
      index: 1,
      image: HomeCategory1,
      title:
        LocalizationLabelsArray.length > 0
          ? replaceLoclizationLabel(
              LocalizationLabelsArray,
              "Pink service for girls",
              "lbl_pink_service"
            )
          : "Pink service for girls",
    },
    {
      id: 2,
      index: 2,
      image: HomeCategory2,
      title:
        LocalizationLabelsArray.length > 0
          ? replaceLoclizationLabel(
              LocalizationLabelsArray,
              "Recovery",
              "lbl_recovery_service"
            )
          : "Recovery",
    },
    {
      id: 3,
      index: 3,
      image: HomeCategory3,
      title:
        LocalizationLabelsArray.length > 0
          ? replaceLoclizationLabel(
              LocalizationLabelsArray,
              "Annual Car maintainance membership",
              "lbl_annual_car"
            )
          : "Annual Car maintainance membership",
    },
    {
      id: 4,
      index: 4,
      image: HomeCategory4,
      title:
        LocalizationLabelsArray.length > 0
          ? replaceLoclizationLabel(
              LocalizationLabelsArray,
              "Car test",
              "lbl_car_test"
            )
          : "Car test",
    },
    {
      id: 5,
      index: 5,
      image: HomeCategory5,
      title:
        LocalizationLabelsArray.length > 0
          ? replaceLoclizationLabel(
              LocalizationLabelsArray,
              "Garage",
              "lbl_garage"
            )
          : "Garage",
    },
  ];

  return (
    <>
      <section className="theme-light">
        <div className="container">
          <div className="text-center">
            <h4>
              {/* At Doos Car Parts, we take pride in providing a comprehensive
              range of services to cater to all your automotive needs. With a
              commitment to excellence and customer satisfaction, we have become
              a reliable name in the automotive industry. Here's a glimpse of
              the diverse services we offer: */}
              {LocalizationLabelsArray.length > 0
                ? replaceLoclizationLabel(
                    LocalizationLabelsArray,
                    "At Doos Car Parts, we take pride in providing a comprehensive range of services to cater to all your automotive needs. With a commitment to excellence and customer satisfaction, we have become a reliable name in the automotive industry. Here's a glimpse of the diverse services we offer:",
                    "lbl_we_offer_home"
                  )
                : "At Doos Car Parts, we take pride in providing a comprehensive range of services to cater to all your automotive needs. With a commitment to excellence and customer satisfaction, we have become a reliable name in the automotive industry. Here's a glimpse of the diverse services we offer:"}
            </h4>
          </div>
          <br />
          <div className="spacer"></div>
          <br />
          <div className="category-grids">
            {categoryData.map((category) => {
              return (
                <div
                  key={category.index}
                  className={`categories-box bottom category-${category.index}`}
                >
                  <img src={category.image} alt="image" />
                  <div className="content">
                    <p className="sub-title">
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Service",
                            "lbl_services"
                          )
                        : "Service"}
                    </p>
                    {/* <p className="sub-title">service</p> */}
                    <h3 className="title">{category.title}</h3>
                    <Link
                      to={`/${getLanguageCodeFromSession()}/all-categories/${
                        category.id
                      }`}
                      className="my-link"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Check details",
                            "lbl_check_details"
                          )
                        : "Check details"}
                      <i className="fas fa-arrow-right"></i>
                      {/* lbl_check_details */}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeCategories;
