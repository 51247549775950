import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import PinkService from '../../resources/themeContent/images/category-product-image/pink-service.jpg';
import RecoveryService from '../../resources/themeContent/images/category-product-image/recovery.jpg';
import MaintenanceService from '../../resources/themeContent/images/category-product-image/maintainace.jpg';
import CarTestingService from '../../resources/themeContent/images/category-product-image/car-test.jpg';
import GarageService from '../../resources/themeContent/images/category-product-image/garage.jpg';
import CarSpareService from '../../resources/themeContent/images/category-product-image/carSparePart.jpg';
import { useNavigate } from 'react-router-dom';
import { GetLocalizationControlsJsonDataForScreen, getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';


const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
  const navigate = useNavigate();
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  const categoryData = [
    {
      id: 1,
      index: 1,
      image: PinkService,
      titleLbl: "lbl_Pink_service",
      descLbl: "lbl_Pink_service_desc",
      title: "Pink service for girls",
      desc: "Empowering our female customers, our Pink Service is tailored to provide a comfortable and trustworthy environment. We prioritize your safety and satisfaction, ensuring a positive experience every time you choose Doos Car Parts.",
    },
    {
      id: 2,
      index: 2,
      image: RecoveryService,
      titleLbl: "lbl_Recovery_Service",
      descLbl: "lbl_Recovery_Service_desc",
      title: "Recovery Service",
      desc: "Accidents and breakdowns happen unexpectedly. Our efficient recovery service ensures that help is just a call away. We prioritize swift and secure recovery, ensuring you get back on the road with minimal disruptions.",
    },
    {
      id: 3,
      index: 3,
      image: MaintenanceService,
      titleLbl: "lbl_Annual_Car_Maintenance_Membership",
      descLbl: "lbl_Annual_Car_Maintenance_Membership_desc",
      title: "Annual Car Maintenance Membership",
      desc: "Invest in the longevity and performance of your vehicle with our Annual Car Maintenance Membership. Enjoy exclusive benefits, timely inspections, and priority services, ensuring your car remains in top-notch condition throughout the year.",
    },
    {
      id: 4,
      index: 4,
      image: CarTestingService,
      titleLbl: "lbl_Car_Testing",
      descLbl: "lbl_Car_Testing_desc",
      title: "Car testing",
      desc: "We understand the importance of a reliable and safe vehicle. Our comprehensive car testing services help identify and address any potential issues, giving you the peace of mind that your vehicle meets the required standards.",
    },
    {
      id: 5,
      index: 5,
      image: GarageService,
      titleLbl: "lbl_Garage_Services",
      descLbl: "lbl_Garage_Services_desc",
      title: "Garage Services",
      desc: "Entrust your vehicle to our skilled technicians at our state-of-the-art garage. From routine maintenance to complex repairs, we offer a wide range of garage services to keep your vehicle running smoothly.",
    },
    {
      id: 6,
      index: 6,
      image: CarSpareService,
      titleLbl: "lbl_car_spare_parts",
      descLbl: "lbl_car_spare_parts_desc",
      title: "Car Spare Parts",
      desc: "Find genuine and high-quality spare parts for your vehicle at Doos Car Parts. Our extensive inventory ensures that you have access to the right components, promoting the longevity and optimal performance of your vehicle.",
    },
  ];

    useEffect(() => {
      // declare the data fetching function
      const dataOperationFunc = async () => {
        //-- Get website localization data
        let arryRespLocalization =
          await GetLocalizationControlsJsonDataForScreen(
            GlobalEnums.Entities["About"],
            null
          );
        if (
          arryRespLocalization != null &&
          arryRespLocalization != undefined &&
          arryRespLocalization.length > 0
        ) {
          await setLocalizationLabelsArray(arryRespLocalization);
        }
      };
      // call the function
      dataOperationFunc().catch(console.error);
    }, []);
  
  
  return (
    <>
      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"} />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb lblKey="lbl_breadcrumb_about" title="About Us" />

      <div className="theme-light">
        <section className="about-area ptb-60">
          <div className="container">
            <div className="about-content">
              <div className="text-start">
                <h2 className="mb-0">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Welcome to Doos Car Parts",
                        "lbl_welcome_to_Doos_Car_Parts"
                      )
                    : "Welcome to Doos Car Parts"}
                </h2>
                <p className="text-muted">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Your Trusted Automotive Partner",
                        "lbl_Your_Trusted_Automotive_Partner"
                      )
                    : "Your Trusted Automotive Partner"}
                </p>
                <br />
                <h4>
                  {/* lbl_about_desc */}

                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "At Doos Car Parts, we take pride in providing a comprehensive range of services to cater to all your automotive needs. With a commitment to excellence and customer satisfaction, we have become a reliable name in the automotive industry. Here's a glimpse of the diverse services we offer:",
                        "lbl_about_desc"
                      )
                    : "At Doos Car Parts, we take pride in providing a comprehensive range of services to cater to all your automotive needs. With a commitment to excellence and customer satisfaction, we have become a reliable name in the automotive industry. Here's a glimpse of the diverse services we offer:"}
                </h4>
              </div>
              <br />
              <div className="grid-about-services">
                {categoryData.map((category) => {
                  return (
                    <div key={category.index} className="about-box">
                      <div className="img-box">
                        <img
                          src={category.image}
                          className="img-fluid"
                          alt="image"
                        />
                      </div>
                      <div className="content">
                        <h4 className="title">
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                category.title,
                                category.titleLbl
                              )
                            : category.title}
                        </h4>
                        <p className="desc">
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                category.desc,
                                category.descLbl
                              )
                            : category.desc}
                        </p>
                        <button
                          onClick={() =>
                            navigate(
                              `/${getLanguageCodeFromSession()}/all-categories/${
                                category.id
                              }`
                            )
                          }
                          className="btn btn-primary"
                        >
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Book Appointment",
                                "lbl_book_appintment"
                              )
                            : "Book Appointment"}
                        </button>
                      </div>
                    </div>
                  );
                })}

                {/* <div className='about-box'>
                        <div className='img-box'>
                          <img src={RecoveryService} className="img-fluid" alt="image" />
                        </div>
                        <div className='content'>
                          <h4 className='title'>Recovery Service:</h4>
                          <p className='desc'>Accidents and breakdowns happen unexpectedly. Our efficient recovery service ensures that help is just a call away. We prioritize swift and secure recovery, ensuring you get back on the road with minimal disruptions.</p>
                          <button className='btn btn-primary'>Book Appoinment</button>
                        </div>
                      </div>

                      <div className='about-box'>
                        <div className='img-box'>
                          <img src={MaintenanceService} className="img-fluid" alt="image" />
                        </div>
                        <div className='content'>
                          <h4 className='title'>Annual Car Maintenance Membership:</h4>
                          <p className='desc'>Invest in the longevity and performance of your vehicle with our Annual Car Maintenance Membership. Enjoy exclusive benefits, timely inspections, and priority services, ensuring your car remains in top-notch condition throughout the year.</p>
                          <button className='btn btn-primary'>Book Appoinment</button>
                        </div>
                      </div>

                      <div className='about-box'>
                        <div className='img-box'>
                          <img src={CarTestingService} className="img-fluid" alt="image" />
                        </div>
                        <div className='content'>
                          <h4 className='title'>Car Testing:</h4>
                          <p className='desc'>We understand the importance of a reliable and safe vehicle. Our comprehensive car testing services help identify and address any potential issues, giving you the peace of mind that your vehicle meets the required standards.</p>
                          <button className='btn btn-primary'>Book Appoinment</button>
                        </div>
                      </div>

                      <div className='about-box'>
                        <div className='img-box'>
                          <img src={GarageService} className="img-fluid" alt="image" />
                        </div>
                        <div className='content'>
                          <h4 className='title'>Garage Services:</h4>
                          <p className='desc'>Entrust your vehicle to our skilled technicians at our state-of-the-art garage. From routine maintenance to complex repairs, we offer a wide range of garage services to keep your vehicle running smoothly.</p>
                          <button className='btn btn-primary'>Book Appoinment</button>
                        </div>
                      </div> */}

                {/* <div className="about-box">
                  <div className="img-box">
                    <img
                      src={CarSpareService}
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div className="content">
                    <h4 className="title">Car Spare Parts:</h4>
                    <p className="desc">
                      Find genuine and high-quality spare parts for your vehicle
                      at Doos Car Parts. Our extensive inventory ensures that
                      you have access to the right components, promoting the
                      longevity and optimal performance of your vehicle.
                    </p>
                    <button
                      onClick={() =>
                        navigate(
                          `/${getLanguageCodeFromSession()}/all-products/6/all-categories`
                        )
                      }
                      className="btn btn-primary"
                    >
                      Book Appointment
                    </button>
                  </div>
                </div> */}
              </div>
              <br />
              <br />
              <br />
              <p>
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "At Doos Car Parts, we are not just a service provider; we are your automotive partners. Our dedicated team is committed to delivering excellence, and we strive to exceed your expectations with every interaction. Choose Doos Car Parts for a seamless and reliable automotive experience.",
                      "lbl_about_desc2"
                    )
                  : "At Doos Car Parts, we are not just a service provider; we are your automotive partners. Our dedicated team is committed to delivering excellence, and we strive to exceed your expectations with every interaction. Choose Doos Car Parts for a seamless and reliable automotive experience."}
              </p>

              <p>
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "Thank you for considering Doos Car Parts as your trusted automotive service provider. We look forward to serving you and your vehicle with the utmost care and professionalism.",
                      "lbl_about_desc3"
                    )
                  : "Thank you for considering Doos Car Parts as your trusted automotive service provider. We look forward to serving you and your vehicle with the utmost care and professionalism."}
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="theme-light">
        <BestFacilities />
      </div>
    </>
  );

}

export default About;
