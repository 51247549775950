import React, { useEffect, useState } from "react";
import { GetLocalizationControlsJsonDataForScreen, getLanguageCodeFromSession, replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const TermsOfUse = () => {

    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

    useEffect(() => {
      // declare the data fetching function
      const dataOperationFunc = async () => {
        let lnCode = getLanguageCodeFromSession();

        //-- Get website localization data
        let arryRespLocalization =
          await GetLocalizationControlsJsonDataForScreen(
            GlobalEnums.Entities["Index_Home_Page"],
            null
          );
        if (
          arryRespLocalization != null &&
          arryRespLocalization != undefined &&
          arryRespLocalization.length > 0
        ) {
          await setLocalizationLabelsArray(arryRespLocalization);
        }
      };
      // call the function
      dataOperationFunc().catch(console.error);
    }, []);

const ServiceTerms = [
  {
    label: "lbl_terms_of_use_1",
    value: "All DOOS Cars service packages come with a unique package ID which is stored in the company's software. Any booklet without or tampered booklet will be confiscated immediately."
  },
  {
    label: "lbl_terms_of_use_2",
    value: "The service package will be deemed as finished when the mentioned number of car services are delivered."
  },
  {
    label: "lbl_terms_of_use_3",
    value: "Service packages can't be cancelled, transferred or refunded."
  },
  {
    label: "lbl_terms_of_use_4",
    value: "DOOS Cars reserves the right to cancel the service packages at any time."
  },
  {
    label: "lbl_terms_of_use_5",
    value: "No car service request will be entertained without a prior appointment. To Book an Appointment call 800-78278."
  },
  {
    label: "lbl_terms_of_use_6",
    value: "If Battmobile's service center is fully occupied, DOOS Cars will have the discretion to perform the minor car service on the customer's location. DOOS Cars will ensure that the service quality is not impacted."
  },
  {
    label: "lbl_terms_of_use_7",
    value: "Monthly installment plans with DOOS Cars and or any third-party entity is subject to changes in the economic situation of the country."
  },
  {
    label: "lbl_terms_of_use_8",
    value: "The claim of up to 50% cheaper than the dealership is based on the average price data collected from randomly selected automobile servicing companies."
  },
  {
    label: "lbl_terms_of_use_9",
    value: "There is no annual commitment with service package however, DOOS Cars reserves the right to limit the period to counter the rising inflation cost."
  },
  {
    label: "lbl_terms_of_use_10",
    value: "The details included in the minor and major service are limited to the information mentioned on the DOOS Cars service package booklet."
  },
  {
    label: "lbl_terms_of_use_11",
    value: "All prices are quoted in Dirhams and are subject to VAT as per UAE's law."
  },
  {
    label: "lbl_terms_of_use_12",
    value: "In case there is a free battery with the purchase of service package, DOOS Cars will use its own brand only. If a customer insists to install a battery other than DOOS Cars, the difference of the price must be paid by the customer."
  },
  {
    label: "lbl_terms_of_use_13",
    value: "Engine oil used for the car servicing will be premium which means it will be either Motul or equivalent quality. Select of oil is on Battmobile's discretion."
  },
  {
    label: "lbl_terms_of_use_14",
    value: "Engine oil filters will be genuine or equivalent quality."
  },
  {
    label: "lbl_terms_of_use_15",
    value: "Free vehicle pick and drop service is subject to the availability of the recovery vehicles and customers will be served on a 'first come first serve' basis."
  },
  {
    label: "lbl_terms_of_use_16",
    value: "Vehicle pick and drop service is available only in Dubai."
  },
  {
    label: "lbl_terms_of_use_17",
    value: "The term 'change' used with brake pads, spark plugs, AC filter and Air Filter refers to the labor charge. DOOS Cars will not charge any amount as 'labor' however the parts are to be purchased by the customer."
  },
  {
    label: "lbl_terms_of_use_18",
    value: "Any repair job, requested by customers but not included in the service package, will only start after the approval and customers must pay for those services."
  },
  {
    label: "lbl_terms_of_use_19",
    value: "Customers sourcing parts from suppliers other than DOOS Cars, will be responsible for the quality of the repair."
  },
  {
    label: "lbl_terms_of_use_20",
    value: "Customers must remove all their valuable belongings from the vehicle. DOOS Cars will not be responsible for any losses or damages."
  },
  {
    label: "lbl_terms_of_use_21",
    value: "DOOS Cars uses spare parts based on the availability and exercises full control over the selection of parts."
  },
  {
    label: "lbl_terms_of_use_22",
    value: "Customers should check their vehicle properly before leaving the DOOS Cars service center premises. No claims will be entertained thereafter."
  },
  {
    label: "lbl_terms_of_use_23",
    value: "If a repair is done using a third party, the charges are to be paid by the customer unless the service is covered within the service package."
  },
  {
    label: "lbl_terms_of_use_24",
    value: "DOOS Cars is not responsible for any delays in the delivery of the vehicle or quality of the repair if it is caused due to 3rd party supplier."
  },
  {
    label: "lbl_terms_of_use_25",
    value: "At the time of receiving the vehicle, make sure your car is properly washed, otherwise raise a complaint with the service advisor."
  },
  {
    label: "lbl_terms_of_use_26",
    value: "Any dispute arising will be subject to the exclusive jurisdiction of the court of law in the UAE."
  },
  {
    label: "lbl_terms_of_use_27",
    value: "Customer has the right to ask about the usage of parts and accessories used for the car service."
  },
  {
    label: "lbl_terms_of_use_28",
    value: "Customer must keep the service booklet safely and present it all the time while obtaining the car service."
  },
  {
    label: "lbl_terms_of_use_29",
    value: "Issuance of replacement service package booklet will cost AED 100+VAT."
  },
  {
    label: "lbl_terms_of_use_30",
    value: "If services have been utilized then you cannot cancel the contract and no refund will be provided."
  },
  {
    label: "lbl_terms_of_use_31",
    value: "After 28 days all service contracts are non-refundable until they expire."
  },
  {
    label: "lbl_terms_of_use_32",
    value: "Car wash service included in the car service package can be availed only when the car is in the service center for a minor or major service."
  },
  {
    label: "lbl_terms_of_use_33",
    value: "Free onsite car wash is not included in the package."
  }
];

  


  return (
    <div className="pandtContent">
      <h1 className="heading">
        {LocalizationLabelsArray.length > 0
          ? replaceLoclizationLabel(
              LocalizationLabelsArray,
              "Terms of Use",
              "lbl_terms_of_use"
            )
          : "Terms of Use"}
      </h1>
      <ul className="content">
        {ServiceTerms.map((data, i) => {
          return (
            <li key={i}>
              {" "}
              {LocalizationLabelsArray.length > 0
                ? replaceLoclizationLabel(
                    LocalizationLabelsArray,
                    data.value,
                    data.label
                  )
                : data.value}
            </li>
          );
        })}
        {/* <li>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          Perspiciatis, exercitationem perferendis. Consequuntur illo minima
          enim ducimus fugit saepe sapiente ipsam tempore commodi, eligendi
          excepturi inventore quisquam possimus. Vel soluta earum cum id vitae
          repudiandae totam inventore dolorum, at tenetur consequuntur modi
          atque quaerat adipisci explicabo incidunt repellendus ea rerum,
          dolorem quas ullam corrupti odio error. Repellat placeat rem mollitia
          dicta consequuntur necessitatibus quas quasi suscipit illum nulla,
          facere molestiae quisquam.
        </li>
        <li>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque labore
          sit, quam, voluptate ullam optio nobis in inventore consequatur fugiat
          similique deserunt nesciunt architecto rem, sapiente totam. Animi sed
          rerum corporis nam nostrum iste, suscipit cum dignissimos recusandae
          modi, quos officiis autem obcaecati corrupti quam numquam? Modi quidem
          veritatis architecto quas dolores! Molestias dignissimos odio cum
          dolorem architecto earum mollitia provident commodi neque, tempore,
          deleniti facilis sequi reiciendis alias dolores cupiditate. Iusto,
          fugit vero! Quas laudantium error praesentium, minima, sit quod autem
          repellat nemo magnam eaque ab adipisci velit, facere esse minus eius
          enim architecto libero molestias. Doloremque vero molestias ab dolor
          quia consequuntur nesciunt? Esse pariatur reprehenderit alias unde
          quasi maiores omnis necessitatibus, sed, laboriosam itaque voluptatum
          aut. Veniam quos amet ex optio a sit delectus, ut qui possimus! Iusto
          tempora quidem tenetur sapiente atque ut nulla incidunt quas ratione
          dignissimos fugit, molestiae assumenda, alias excepturi modi,
          consectetur exercitationem.
        </li> */}
      </ul>
    </div>
  );
};

export default TermsOfUse;
