import Lottie from "lottie-react";
import React from "react";
import successLottie from "../../resources/themeContent/lottie/success-lottie.json";
import failedLottie from "../../resources/themeContent/lottie/failed-lottie.json";
import { useNavigate, useParams } from "react-router-dom";

const PaymenResult = () => {
  const { status } = useParams();
  const navigate = useNavigate();
  return (
    <div className="paymentResultPage">
      <div className="img">
        <Lottie
          animationData={status === "success" ? successLottie : failedLottie}
          loop={true}
        />
      </div>
      <div className="text">
        {status === "success" ? (
          <>
            <h2>Thank you for your purchase!</h2>
            <p>
              Your order has been successfully processed. We've emailed you a
              receipt.
            </p>
          </>
        ) : (
          <>
            <h2>Order is failed !</h2>
            <p>
              Your order has been failed beacuse of some reasons. Please try
              again
            </p>
          </>
        )}
        <div className="buttonGroup">
          <button
            className="btn btn-primary d-flex align-items-center gap-3 justify-content-center "
            onClick={() => navigate("/")}
          >
            Back to home
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymenResult;
