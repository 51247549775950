import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import BestFacilities from "./BestFacilities";
import ProductsFilterOptions from "../products/ProductsFilterOptions";
import ProductsGridTypeOne from "../products/ProductsGridTypeOne";
import { SiteLeftSidebarFilter } from "./SiteLeftSidebarFilter";
import SitePagination from "./SitePagination";

import SubscribeNewsLetter from "./SubscribeNewsLetter";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import { useSelector, useDispatch } from "react-redux";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";
import { Helmet } from "react-helmet";
import GlobalEnums from "../../../helpers/GlobalEnums";
// import { GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel, ScrollIntoSpecificDiv } from '../../../helpers/CommonHelper';
import {
  checkIfStringIsEmtpy,
  showInfoMsg,
} from "../../../helpers/ValidationHelper";
import {
  makeAnyStringLengthShort,
  makeProductShortDescription,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import {
  GetDefaultCurrencySymbol,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";

import serviceImg1 from "../../resources/themeContent/images/category-product-image/carSparePart.jpg";

const AllParentCategories = () => {
    const dispatch = useDispatch();
    const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
    const [ProductListMainClass, setProductListMainClass] = useState(
      "col-lg-4 col-sm-6 col-md-4 col-6 products-col-item"
    );
    const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);
    const [CategoriesList, setCategoriesList] = useState([]);
    const [langCode, setLangCode] = useState("");
    const [LogoImageFromStorage, setLogoImageFromStorage] = useState("");
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [productList, setProductList] = useState([]);

  //--set product id from url
  const params = useParams();
  const { parent_category_id } = useParams();
   let categParamArray = [];
   categParamArray.push(parseInt(params.category_id) ?? 0);



  const navigateOnCategoryClick = (
    CategoryID,
    categoryName,
    ProductName,
    ProductID,
    name,
    e
  ) => {
    // e.preventDefault();

    CategoryID = CategoryID ?? 0;
    categoryName = categoryName ?? "all-categories";

    let newPageUrl = `/${getLanguageCodeFromSession()}/all-categories`;
    // let newPageUrl = `/${getLanguageCodeFromSession()}/all-products/${CategoryID}`;
    // let newPageUrl = `/${getLanguageCodeFromSession()}/product-detail/${ProductID}/${replaceWhiteSpacesWithDashSymbolInUrl(CategoryName) ?? "shop"}/${replaceWhiteSpacesWithDashSymbolInUrl(name)}`

    window.location.href = newPageUrl;

    // if (pathName.includes("AllProducts")) {
    //     window.location.href = newPageUrl;
    // } else {
    //     navigate(newPageUrl);
    // }

    //  navigate(newPageUrl);
  };

    useEffect(() => {
      const getAllProducts = async () => {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

         const categoryparam = {
           requestParameters: {
             PageNo: 1,
             PageSize: 100,
             recordValueJson: "[]",
           },
         };

         //--Get categories list
         const categoriesResponse = await MakeApiCallAsync(
           Config.END_POINT_NAMES["GET_CATEGORIES_LIST"],
           null,
           categoryparam,
           headers,
           "POST",
           true
         );
        
        if (categoriesResponse != null && categoriesResponse.data != null) {
          const parseCats = JSON.parse(categoriesResponse.data.data);

          const findCats = parseCats.filter(
            (cat) => cat.ParentCategoryID == parent_category_id
          );

          const findIds = findCats.map((category) => category.CategoryID);
          const param = {
            requestParameters: {
              SearchTerm: "",
              SizeID: "",
              ColorID: null,
              // CategoryID: CategoryID.join(","),
              CategoryID: findIds.join(","),
              TagID: "",
              ManufacturerID: "",
              MinPrice: null,
              MaxPrice: null,
              Rating: null,
              OrderByColumnName: "",
              PageNo: 1,
              PageSize: 9,
              recordValueJson: "[]",
            },
          };
  
          // setshowPagination(false);
  
          const response = await MakeApiCallAsync(
            Config.END_POINT_NAMES["GET_All_PRODUCTS"],
            null,
            param,
            headers,
            "POST",
            true
          );
          if (response != null && response.data != null) {
            const parseData = JSON.parse(response.data.data);
            setProductList(parseData);
            // await setProductsList(JSON.parse(response.data.data));
            // let AllProducts = JSON.parse(response.data.data);
            // await setTotalRecords(parseInt(AllProducts[0]?.TotalRecords ?? 0));
            // console.log(JSON.parse(response.data.data));
  
            // if (AllProducts.length > 0) {
            //   await setshowPagination(true);
            // }
          }
  
          //-- Get website localization data
          let arryRespLocalization =
            await GetLocalizationControlsJsonDataForScreen(
              GlobalEnums.Entities["AllProducts"],
              null
            );
          if (
            arryRespLocalization != null &&
            arryRespLocalization != undefined &&
            arryRespLocalization.length > 0
          ) {
            await setLocalizationLabelsArray(arryRespLocalization);
          }
        }

      };

      //--start loader
      dispatch(rootAction.commonAction.setLoading(true));

      // call the function
      getAllProducts().catch(console.error);

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);

      window.scrollTo(0, 0);

    }, [parent_category_id]);

    console.log(productList);


  return (
    <>
      <Helmet>
        <title>{siteTitle} - All Category</title>
        <meta name="description" content={siteTitle + " - All Category"} />
        <meta name="keywords" content="All Category"></meta>
      </Helmet>

      {/* <div className="theme-light">
        <section className="section-padding all-categories-section">
          <div className="container">
            <div className="section-title">
              <h2>
                <span id="lbl_allprd_allproduct">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Services",
                        "lbl_allprd_allproduct"
                      )
                    : "Services"}
                </span>
              </h2>
            </div>
            <br />
            {/* {CategoriesList?.map((item, idx) => {
              return ( 
            <>
              <div className="py-3">
                <h3 className="title uppr text-primary-2">
                  {langCode != null &&
                  langCode == Config.LANG_CODES_ENUM["Arabic"]
                    ? CategoriesList[0]?.LocalizationJsonData != null &&
                      CategoriesList[0]?.LocalizationJsonData.length > 0
                      ? makeAnyStringLengthShort(
                          CategoriesList[0].LocalizationJsonData?.find(
                            (l) =>
                              l.langId == Config.LANG_CODES_IDS_ENUM["Arabic"]
                          )?.text,
                          30
                        )
                      : makeAnyStringLengthShort(
                          CategoriesList[0]?.ParentCategoryName,
                          30
                        )
                    : makeAnyStringLengthShort(
                        CategoriesList[0]?.ParentCategoryName,
                        30
                      )}
                </h3>
                <hr />

                <ul className="grid-categories">
                  {CategoriesList?.map((item, idx) => {
                    return (
                      <li className="service-box">
                        <div className="img-box">
                          <img
                            src={serviceImg1}
                            className="img-fluid"
                            alt="image"
                          />
                        </div>
                        <div className="content">
                          <h6 className="title">
                            {langCode != null &&
                            langCode == Config.LANG_CODES_ENUM["Arabic"]
                              ? item.LocalizationJsonData != null &&
                                item.LocalizationJsonData.length > 0
                                ? makeAnyStringLengthShort(
                                    item.LocalizationJsonData?.find(
                                      (l) =>
                                        l.langId ==
                                        Config.LANG_CODES_IDS_ENUM["Arabic"]
                                    )?.text,
                                    30
                                  )
                                : makeAnyStringLengthShort(item.Name, 30)
                              : makeAnyStringLengthShort(item.Name, 30)}
                          </h6>
                          <p class="desc">{item.Description}</p>
                          {/* <div className="service-price">
                            <span className="new-price">
                              <del>$1300</del>
                              &nbsp; $800
                            </span>
                          </div> 
                        </div>

                        <Link
                          to={`/${getLanguageCodeFromSession()}/all-products/${
                            item.CategoryID
                          }/${replaceWhiteSpacesWithDashSymbolInUrl(
                            item.categoryName
                          )}`}
                          onClick={(e) => {
                            // navigateOnCategoryClick(elementChild.CategoryID, e);
                            navigateOnCategoryClick(
                              item.CategoryID,
                              item.categoryName,
                              e
                            );
                          }}
                          className="btn btn-primary"
                        >
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Book Now",
                                "lbl_allprd_allproduct"
                              )
                            : "Book Now"}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
            })} 
          </div>
        </section>
      </div> */}

      <div className="theme-light">
        <section className="all-categories-section">
          <div className="container">
            <div className="section-title">
              <h2>
                <span id="lbl_allprd_allproduct">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Services",
                        "lbl_allprd_allproduct"
                      )
                    : "Services"}
                </span>
              </h2>
            </div>
            <br />
            <ul className="grid-categories">
              {/* {productList.length > 0 ? (
                productList?.map((item, idx) => {
                  return (
                    <div className={ProductListMainClass} key={item.ProductId}>
                      <div className="single-product-box">
                        <div className="product-image">
                          {(() => {
                            let urlViewDetailImage = `/${getLanguageCodeFromSession()}/product-detail/${
                              item.ProductId
                            }/${
                              replaceWhiteSpacesWithDashSymbolInUrl(
                                item.CategoryName
                              ) ?? "shop"
                            }/${replaceWhiteSpacesWithDashSymbolInUrl(
                              item.ProductName
                            )}`;
                            return (
                              <>
                                <Link
                                  to={urlViewDetailImage}
                                  onClick={() =>
                                    reloadProductDetail(
                                      item.ProductId,
                                      item.CategoryName,
                                      item.ProductName
                                    )
                                  }
                                >
                                  {item?.ProductImagesJson?.map(
                                    (img, imgIdx) => (
                                      <>
                                        <img
                                          src={
                                            adminPanelBaseURL +
                                            img.AttachmentURL
                                          }
                                          alt="image"
                                          key={imgIdx}
                                        />
                                      </>
                                    )
                                  )}
                                </Link>
                              </>
                            );
                          })()}
                          <ul>
                            <li>
                              <Link
                                className="fab-btn"
                                to="#"
                                data-tip="Add to Wishlist"
                                data-place="left"
                                onClick={(e) => {
                                  e.preventDefault();
                                  HandleCustomerWishList(
                                    item.ProductId,
                                    item.ProductName,
                                    item.Price,
                                    item.DiscountedPrice,
                                    item.DiscountId,
                                    item.IsDiscountCalculated,
                                    item.CouponCode,
                                    item?.ProductImagesJson[0]?.AttachmentURL !=
                                      undefined
                                      ? item?.ProductImagesJson[0]
                                          ?.AttachmentURL
                                      : ""
                                  );
                                }}
                              >
                                <i className="far fa-heart"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        <div className="product-content">
                          <h3>
                            <Link
                              to={`/${getLanguageCodeFromSession()}/product-detail/${
                                item.ProductId
                              }/${
                                replaceWhiteSpacesWithDashSymbolInUrl(
                                  item.CategoryName
                                ) ?? "shop"
                              }/${replaceWhiteSpacesWithDashSymbolInUrl(
                                item.ProductName
                              )}`}
                            >
                              {makeProductShortDescription(
                                item.ProductName,
                                45
                              )}
                            </Link>
                          </h3>

                          <div className="product-price">
                            <span className="new-price">
                              {item.DiscountedPrice != undefined &&
                              item.DiscountedPrice > 0 ? (
                                <>
                                  <del>
                                    {GetDefaultCurrencySymbol()}
                                    {item.Price}
                                  </del>{" "}
                                  &nbsp; {GetDefaultCurrencySymbol()}
                                  {item.DiscountedPrice}
                                </>
                              ) : (
                                <>
                                  {GetDefaultCurrencySymbol()}
                                  {item.Price}
                                </>
                              )}
                            </span>
                          </div>
                          {(() => {
                            let urlViewDetail = `/${getLanguageCodeFromSession()}/product-detail/${
                              item.ProductId
                            }/${
                              replaceWhiteSpacesWithDashSymbolInUrl(
                                item.CategoryName
                              ) ?? "shop"
                            }/${replaceWhiteSpacesWithDashSymbolInUrl(
                              item.ProductName
                            )}`;
                            return (
                              <>
                                <Link
                                  to={urlViewDetail}
                                  onClick={() =>
                                    reloadProductDetail(
                                      item.ProductId,
                                      item.CategoryName,
                                      item.ProductName
                                    )
                                  }
                                  className="btn btn-primary"
                                  id="lbl_prd_gridone_viewdetail"
                                >
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "View Detail",
                                        "lbl_prd_gridone_viewdetail"
                                      )
                                    : "View Detail"}
                                </Link>
                              </>
                            );
                          })()}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No Services Found</p>
              )} */}
              {productList.length > 0 ? (
                <ul className="grid-categories">
                  {productList?.map((item, idx) => {
                    let urlViewDetailImage = `/${getLanguageCodeFromSession()}/product-detail/${
                      item.ProductId
                    }/${
                      replaceWhiteSpacesWithDashSymbolInUrl(
                        item.CategoryName
                      ) ?? "shop"
                    }/${replaceWhiteSpacesWithDashSymbolInUrl(
                      item.ProductName
                    )}`;
                    let urlViewDetail = `/book-appointment/${item.ProductId}`;
                    return (
                      <li className="service-box">
                        <div className="img-box">
                          {item?.ProductImagesJson?.map((img, imgIdx) => (
                            <>
                              <img
                                src={adminPanelBaseURL + img.AttachmentURL}
                                alt="image"
                                key={imgIdx}
                              />
                            </>
                          ))}
                        </div>
                        <div className="content">
                          <h6 className="title">
                            {makeProductShortDescription(item.ProductName, 45)}
                          </h6>
                          <p class="desc">{item.ShortDescription}</p>
                          <div className="service-price">
                            <span className="new-price">
                              {/* <del>
                                {GetDefaultCurrencySymbol()}
                                {item.Price}
                              </del> */}
                              &nbsp; {GetDefaultCurrencySymbol()}
                              {item.Price}
                            </span>
                          </div>
                          <div>
                            <p style={{ marginBottom: "0%" }}>
                              <strong>Vendor Email : </strong>
                              {item.VendorEmail}
                            </p>
                            <p style={{ marginBottom: "0%" }}>
                              <strong>Vendor Mobile No : </strong>
                              {item.VendorMobileNo}
                            </p>
                          </div>
                        </div>

                        <Link to={urlViewDetail} className="btn btn-primary">
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Book Now",
                                "lbl_allprd_allproduct"
                              )
                            : "Book Now"}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              ) : <p style={{textAlign:"center"}} >No Products Available</p>}
            </ul>
          </div>
        </section>
      </div>

      <div className="theme-light">
        <BestFacilities />
      </div>
      <SubscribeNewsLetter />
    </>
  );
};

export default AllParentCategories;
