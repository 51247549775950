import React, { useEffect, useState } from 'react';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import { Helmet } from 'react-helmet';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Config from '../../../helpers/Config';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';


const FaqPage = () => {
    const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [langCode, setLangCode] = useState('');

    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {
            let lnCode = getLanguageCodeFromSession();
            setLangCode(lnCode);

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["FaqPage"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [])
  
  const faqData = [
    {
      id: 1,
      question: "What is included in DOOS car inspection service?",
      answer:
        "Our thorough car inspection covers a wide range of areas, such as the health of the engine, the condition of mechanical components, the state of the interior and exterior, and a detailed diagnostic report.",
      questionLabel: "lbl_faq_q1",
      answerLabel: "lbl_faq_a1",
    },
    {
      id: 2,
      question: "How can I book a car inspection with DOOS Cars in Dubai?",
      answer:
        "Simply visit our website or call on (+971) 52 113 8699 to schedule your car inspection at a time convenient for you.",
      questionLabel: "lbl_faq_q2",
      answerLabel: "lbl_faq_a2",
    },
    {
      id: 3,
      question: "Why is a car inspection important when buying a used vehicle?",
      answer:
        "A car inspection guarantees that you are well-informed when making a decision, as it offers valuable insights into the condition of the vehicle. This helps you steer clear of any possible problems and unforeseen costs.",
      questionLabel: "lbl_faq_q3",
      answerLabel: "lbl_faq_a3",
    },
    {
      id: 4,
      question: "How long does a car inspection usually take?",
      answer:
        "The duration of the inspection will vary based on the level of thoroughness needed. However, our team is dedicated to completing inspections promptly while ensuring accuracy is not compromised.",
      questionLabel: "lbl_faq_q4",
      answerLabel: "lbl_faq_a4",
    },
    {
      id: 5,
      question: "Do I need to be present during the car inspection?",
      answer:
        "Although it is not required, attending the appointment will give you the opportunity to address any specific concerns with our technicians and enhance your understanding of the inspection results.",
      questionLabel: "lbl_faq_q5",
      answerLabel: "lbl_faq_a5",
    },
    {
      id: 6,
      question: "Can I schedule a car inspection on weekends or public holidays?",
      answer:
        "Yes, DOOS Cars offers flexible scheduling, including weekends and public holidays, to accommodate your busy lifestyle.",
      questionLabel: "lbl_faq_q6",
      answerLabel: "lbl_faq_a6",
    },
    {
      id: 7,
      question: "Is the diagnostic report provided immediately after the inspection?",
      answer:
        "Yes, you'll receive a detailed diagnostic report within 6 hours after the inspection, outlining the condition of various components.",
      questionLabel: "lbl_faq_q7",
      answerLabel: "lbl_faq_a7",
    },
    {
      id: 8,
      question: "Can I use the inspection report for price negotiation when buying a used car?",
      answer:
        "Absolutely! The report empowers you with valuable information that can be used in negotiations or to make an informed decision on the purchase.",
      questionLabel: "lbl_faq_q8",
      answerLabel: "lbl_faq_a8",
    },
    {
      id: 9,
      question: "What payment options are available for the car inspection service?",
      answer:
        "We offer convenient payment options, including online payment through our secure portal or payment at the time of the inspection.",
      questionLabel: "lbl_faq_q9",
      answerLabel: "lbl_faq_a9",
    },
    {
      id: 10,
      question: "Are DOOS Car technicians certified and experienced?",
      answer:
        "Yes, our technicians are highly trained, certified, and experienced in conducting thorough car inspections to ensure accuracy and reliability.",
      questionLabel: "lbl_faq_q10",
      answerLabel: "lbl_faq_a10",
    },
    ]



    return (
      <>
        <Helmet>
          <title>{siteTitle} - Frequently Asked Questions (FAQ)</title>
          <meta
            name="description"
            content={siteTitle + " - Frequently Asked Questions (FAQ)"}
          />
          <meta
            name="keywords"
            content="Frequently Asked Questions, FAQ"
          ></meta>
        </Helmet>

        <SiteBreadcrumb lblKey="lbl_breadcrumb_faq" title="FAQ's" />

        <div className="theme-light">
          <section className="faq-area ptb-60">
            <div className="container">
              <div className="section-title">
                <h2 className="main-title">
                  <span id="lbl_faq_pagetitle">
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Frequently Asked Questions",
                          "lbl_faq_pagetitle"
                        )
                      : "Frequently Asked Questions"}
                  </span>
                </h2>
              </div>

              <div className="faq-accordion">
                <ul className="accordion">
                  <Accordion>
                    {faqData.map((item, index) => (
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  item.question,
                                  item.questionLabel
                                )
                              : item.question}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  item.answer,
                                  item.answerLabel
                                )
                              : item.answer}
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                    {/* <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          What is included in DOOS car inspection service?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Our thorough car inspection covers a wide range of
                          areas, such as the health of the engine, the condition
                          of mechanical components, the state of the interior
                          and exterior, and a detailed diagnostic report.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem> */}
                  </Accordion>
                </ul>
              </div>
            </div>
          </section>
          <BestFacilities />
        </div>
      </>
    );

}

export default FaqPage;
