
import React, { useEffect, useState } from 'react';
import { MakeApiCallSynchronous, MakeApiCallAsync } from '../../../helpers/ApiHelpers';
import Config from '../../../helpers/Config';
import ProductsGridTypeOne from '../products/ProductsGridTypeOne';
import { useSelector, useDispatch } from 'react-redux';
import rootAction from '../../../stateManagment/actions/rootAction';
import { LOADER_DURATION } from '../../../helpers/Constants';
import { Link } from 'react-router-dom';
import { getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';


const NewProducts = () => {
    const dispatch = useDispatch();
    const [ProductsList, setProductsList] = useState([]);
    const [ProductListMainClass, setProductListMainClass] = useState("col-lg-3 col-sm-6 col-6");
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [PaginationInfo, setPaginationInfo] = useState({
        PageNo: 1,
        PageSize: 20,
        TotalRecords: 0
    });



    useEffect(() => {
        // declare the data fetching function
        const getNewProductsList = async () => {


            const headers = {
                // customerid: userData?.UserID,
                // customeremail: userData.EmailAddress,
                Accept: 'application/json',
                'Content-Type': 'application/json',

            }


            const param = {
                requestParameters: {
                    PageNo: PaginationInfo.PageNo,
                    PageSize: PaginationInfo.PageSize,
                    TabName: "new products",
                    recordValueJson: "[]",
                },
            };


            
            const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_RECENTS_PRODUCTS_LIST'], null, param, headers, "POST", true);
            if (response != null && response.data != null) {
                await setProductsList(JSON.parse(response.data.data));
                console.log(JSON.parse(response.data.data));
            }

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["NewProducts"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }


        }

        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        // call the function
        getNewProductsList().catch(console.error);

        //--stop loader
        setTimeout(() => {
            dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);

    }, [])


    return (
      <>
        <section className="products-area theme-light">
          <div className="container">
            <div className="section-title max-80">
              <p className="sub-title">
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "Spare Parts",
                      "lbl_spare_parts"
                    )
                  : "Spare Parts"}
              </p>
              <h2 className="main-title">
                <span id="lbl_rcntprd_title">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Recent Products",
                        "lbl_rcntprd_title"
                      )
                    : "Recent Products"}
                </span>
              </h2>
              <p>
                {/* lbl_recent_products_desc */}
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "Find genuine and high-quality spare parts for your vehicle at Doos Car Parts. Our extensive inventory ensures that you have access to the right components, promoting the longevity and optimal performance of your vehicle.",
                      "lbl_recent_products_desc"
                    )
                  : "Find genuine and high-quality spare parts for your vehicle at Doos Car Parts. Our extensive inventory ensures that you have access to the right components, promoting the longevity and optimal performance of your vehicle."}
              </p>
            </div>
            <div className="row">
              <ProductsGridTypeOne
                ProductsList={ProductsList}
                ProductListMainClass={ProductListMainClass}
              />
            </div>
            <br />
            <div className="products-view-all text-center">
              <Link
                className="btn btn-primary-2"
                to={`/${getLanguageCodeFromSession()}/all-products/0/recent-products`}
                id="lbl_rcntprd_viewall"
              >
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "View All",
                      "lbl_popprd_viewall"
                    )
                  : "View All"}
              </Link>
            </div>
          </div>
        </section>
      </>
    );

}


export default NewProducts;
