import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Config from "../../../helpers/Config";
import {
  getFileExtensionFromContentType,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import { GetUserIdForHeader } from "../../../helpers/CommonHelper";
import InvoicePDF from "../pdf/Pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";

const OrderHistoryDetails = ({
  closeDetailModel,
  openDetailModel,
  OrderHistoryDetails,
  product,
}) => {
  const [SizeList, setSizeList] = useState([]);
  const [adminPanelBaseURL, setadminPanelBaseURL] = useState(
    Config["ADMIN_BASE_URL"]
  );
  console.log(product);
  const [OrderItemsDetailList, setOrderItemsDetailList] = useState([]);
  console.log(product, "From popup model");

  const downloadDigitalProduct = async (OrderItemID, ProductName) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let UserID = await GetUserIdForHeader();
    let fileUrl =
      Config["ADMIN_BASE_URL"] +
      Config["COMMON_CONTROLLER_SUB_URL"] +
      Config.END_POINT_NAMES["DOWNLOAD_DIGITAL_FILE"];
    fileUrl = `${fileUrl}/${OrderItemID ?? 0}/${UserID}`;

    const response = await fetch(fileUrl, {
      headers: headers,
    });

    const contentType = await response.headers.get("content-type");
    const fileExtension = getFileExtensionFromContentType(contentType);

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    let FileName = replaceWhiteSpacesWithDashSymbolInUrl(ProductName);
    FileName = FileName ?? "Your_Digital_Product";
    link.setAttribute("download", FileName + fileExtension);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  return (
    <Modal
      isOpen={OrderHistoryDetails}
      centered={true}
      toggle={closeDetailModel}
    >
      <ModalHeader>
        Product Detail
        <i className="fa fa-close modal-close" onClick={closeDetailModel}></i>
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            {product ? (
              <>
                {product.map((singleItem) => {
                  const vatRate = 0.5;
                  const calculatedPriceWithVat =
                    singleItem.OrderItemTotal * (1 + vatRate);
                  return (
                    <div className="orderHistoryModel mt-5">
                      <a href="#">
                        <img
                          src={adminPanelBaseURL + singleItem.DefaultImageUrl}
                          alt="product"
                          className=""
                        />
                      </a>
                      <div className="detailsOrderHistory">
                        <h1>{singleItem.ProductName}</h1>
                        {/* <div className="priceAndQty">
                  </div> */}
                        <p>PRICE : {singleItem.Price}</p>
                        <p>QTY:{singleItem.Quantity}</p>
                        {/* <p>VAT : 5%</p> */}
                        <p className="orderHistoryTotal">
                          TOTAL: AED {singleItem.OrderItemTotal}
                        </p>
                      </div>
                    </div>
                  );
                })}

                <div className="orderHistoryBtn">
                  <button
                    to="#"
                    className="digital-download-btn"
                    // onClick={() => {
                    //   downloadDigitalProduct(
                    //     product.OrderItemID,
                    //     product.ProductName
                    //   );
                    // }}
                  >
                    <PDFDownloadLink
                      document={
                        <InvoicePDF
                          customerName={"Name"}
                          orderId={product[0]?.OrderID}
                          date={"DD/MM/YYYY"}
                          product={product}
                          total={product[0]?.OrderItemTotal}
                        />
                      }
                      fileName={`invoice.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        error ? error : "Download PDF"
                      }
                    </PDFDownloadLink>
                  </button>
                  <button
                    to="#"
                    className="digital-download-btn"
                    onClick={() => {
                      downloadDigitalProduct(
                        product.OrderItemID,
                        product.ProductName
                      );
                    }}
                  >
                    Email
                  </button>
                </div>
              </>
            ) : null}

            {/* <table className="table table-striped">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Name</th>
                  <th>Unit price</th>
                  <th>Qty</th>
                  <th>Totel</th>
                  <th>Digital product</th>
                </tr>
              </thead>

              <tbody>
                {product ? (
                  <tr>
                    <td>
                      <a href="#">
                        <Media
                          src={adminPanelBaseURL + product.DefaultImageUrl}
                          alt="product"
                          className="img-fluid"
                        />
                      </a>
                    </td>
                    <td>{product.ProductName}</td>
                    <td>{product.Price}</td>
                    <td>{product.Quantity}</td>
                    <td>{product.OrderItemTotal}</td>
                    <td>
                      <span>
                        {product?.IsDigitalProduct != undefined &&
                        product.IsDigitalProduct == true &&
                        (product.LatestStatusID ==
                          Config.ORDER_STATUS["Completed"] ||
                          product.ShippingStatusID ==
                            Config.ORDER_STATUS["Completed"]) ? (
                          <Link
                            to="#"
                            className="digital-download-btn"
                            onClick={() => {
                              downloadDigitalProduct(
                                product.OrderItemID,
                                product.ProductName
                              );
                            }}
                          >
                            Download
                          </Link>
                        ) : product?.IsDigitalProduct == true ? (
                          "Yes (In Progress)"
                        ) : (
                          "No"
                        )}
                      </span>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table> */}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OrderHistoryDetails;
