import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import bannerDownloadImg from '../../resources/themeContent/images/banner-phones-big.png';
import btnPlayStore from '../../resources/themeContent/images/google-play-badge.png';
import btnAppStore from '../../resources/themeContent/images/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg';
import { GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';


const BannerDownloadApp = () => {
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        //-- Get website localization data
        let arryRespLocalization =
          await GetLocalizationControlsJsonDataForScreen(
            GlobalEnums.Entities["Index_Home_Page"],
            null
          );
        if (
          arryRespLocalization != null &&
          arryRespLocalization != undefined &&
          arryRespLocalization.length > 0
        ) {
          await setLocalizationLabelsArray(arryRespLocalization);
          console.log(arryRespLocalization, "<-- arryRespLocalization");
        }
      } catch (error) {
        console.log(error,"<-- error")
      }
    }

    fetchData();
    
  }, [])

    return (
      <>
        <section className="">
          <div className="container">
            <div className="bannerDownload-wrapper">
              <div className="imgBox">
                <img src={bannerDownloadImg} alt="" className="img-fluid" />
              </div>
              <div className="content">
                <h4 className="title">
                  {/* lbl_download_app */}
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Book Appointment Download This App",
                        "lbl_download_app"
                      )
                    : "Book Appointment Download This App"}
                </h4>
                <p>
                  {/* At Doos Car Parts, we are not just a service provider; we are
                  your automotive partners. Our dedicated team is committed to
                  delivering excellence, and we strive to exceed your
                  expectations with every interaction. Choose Doos Car Parts for
                  a seamless and reliable automotive experience. */}
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "At Doos Car Parts, we are not just a service provider; we are your automotive partners. Our dedicated team is committed to delivering excellence, and we strive to exceed your expectations with every interaction. Choose Doos Car Parts for a seamless and reliable automotive experience.",
                        "lbl_download_app_desc"
                      )
                    : "At Doos Car Parts, we are not just a service provider; we are your automotive partners. Our dedicated team is committed to delivering excellence, and we strive to exceed your expectations with every interaction. Choose Doos Car Parts for a seamless and reliable automotive experience."}
                </p>
                <br />
                <div className="btns-group">
                  <Link to="#">
                    <img
                      src={btnPlayStore}
                      alt=""
                      className="img-fluid playstore"
                    />
                  </Link>
                  <Link to="#">
                    <img
                      src={btnAppStore}
                      alt=""
                      className="img-fluid appstore"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );

}


export default BannerDownloadApp;
