import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import { useSelector, useDispatch } from "react-redux";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  showErrorMsg,
  showSuccessMsg,
  validateAnyFormField,
} from "../../../helpers/ValidationHelper";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import rootAction from "../../../stateManagment/actions/rootAction";
import { Helmet } from "react-helmet";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const BookApoinment = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recipients = ["jaydeepkhachriya7822@gmail.com"];
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [Name, setName] = useState("");
  const [attechment, setAttechment] = useState();
  const [EmailAddress, setEmailAddress] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Message, setMessage] = useState("");
  const [ProductDetail, setProductDetail] = useState([]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(true));
    }, LOADER_DURATION);
    try {
      const params = {
        email: EmailAddress,
        name: Name,
        phone: MobileNo,
        description: Message,
        productDetail: ProductDetail.ProductId,
        // file: attechment,
      };

      const formData = new FormData();

      for (let key in params) {
        formData.append(key, params[key]);
      }

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const postOrder = await MakeApiCallAsync(
        Config.END_POINT_NAMES["SEND_QUOTE"],
        Config.COMMON_CONTROLLER_SUB_URL,
        formData,
        headers,
        "POST",
        true
      );

      if (postOrder.data.statusCode === 200) {
        showSuccessMsg(postOrder.data.message);
        setTimeout(() => {
          dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
      }
    } catch (error) {
      console.log("error while sending quote", error);
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        const param = {
          requestParameters: {
            ProductId: id,
            recordValueJson: "[]",
          },
        };

        //--Get product detail
        const response = await MakeApiCallAsync(
          Config.END_POINT_NAMES["GET_PRODUCT_DETAIL"],
          null,
          param,
          headers,
          "POST",
          true
        );
        if (response != null && response.data != null) {
          let detail = JSON.parse(response.data.data);
          console.log(detail)
          setProductDetail(detail[0]);
        }
      } catch (error) {
        console.log("Get product details error --->", error);
      }
    };
    fetchProductDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Book Appointment</title>
        <meta name="description" content={siteTitle + " - Book Appointment"} />
        <meta name="keywords" content="Book Appointment"></meta>
      </Helmet>
      <SiteBreadcrumb title="Book Appointment" />

      <div className="theme-light">
        <section className="signup-area ptb-60">
          <div className="container">
            <div className="signup-content">
              <div className="section-title">
                <h2 className="main-title">
                  <span id="lbl_singup_crtaccount">
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Book Appointment",
                          "lbl_singup_crtaccount"
                        )
                      : "Book Appointment"}
                  </span>
                </h2>
                {/* <br />
                <br />
                <h4 className="text-primary-2">
                  Year-round Care, Hassle-Free Rides
                </h4>
                <p>
                  Invest in the logevity and performance of your vehicle with
                  our annual car maintenance membership. enjoy exclusive
                  benefits, timely inspections and priority services, ensuring
                  your car remains in top-notch condition throught the year.
                </p> */}
              </div>

              <form onSubmit={handleSubmit} className="signup-form">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Full Name",
                              "lbl_singup_fname"
                            )
                          : "Full Name"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter full name"
                        id="fullName"
                        name="fullName"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Email",
                              "lbl_singup_email"
                            )
                          : "Email"}
                        <span className="required-field">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        id="EmailAddress"
                        name="EmailAddress"
                        required={true}
                        value={EmailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Contact No",
                              "lbl_singup_contact"
                            )
                          : "Contact No"}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter contact no"
                        id="MobileNo"
                        name="MobileNo"
                        value={MobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Date",
                              "lbl_singup_pcode"
                            )
                          : "Date"}
                      </label>
                      <input type="date" className="form-control" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Time",
                              "lbl_singup_pcode"
                            )
                          : "Time"}
                      </label>
                      <input type="time" className="form-control" />
                    </div>
                  </div> */}

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Message",
                              "lbl_singup_fname"
                            )
                          : "Message"}
                      </label>
                      <textarea
                        className="form-control"
                        rows="7"
                        placeholder="Your Message"
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="lbl_singup_submitbnt"
                  >
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Book Now",
                          "lbl_singup_submitbnt"
                        )
                      : "Book Now"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>

        <BestFacilities />
      </div>
    </>
  );
};

export default BookApoinment;
