import React, { useEffect, useState } from "react";
import { GetLocalizationControlsJsonDataForScreen, getLanguageCodeFromSession, replaceLoclizationLabel } from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const PrivacyPolicy = () => {
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Index_Home_Page"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);


  return (
    <div className="pandtContent">
      <h1 className="heading">
        {LocalizationLabelsArray.length > 0
          ? replaceLoclizationLabel(
              LocalizationLabelsArray,
              "Privacy Policy",
              "lbl_privacy_policy"
            )
          : "Privacy Policy"}
      </h1>
      <ul className="content">
        <li>
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "Privacy Policy A&F Auto Repair LLC built the DOOS Cars app as a Commercial app. This SERVICE is provided by A&F Auto Repair LLC and is intended for use as is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at DOOS Cars unless otherwise defined in this Privacy Policy.",
                "lbl_privacy_policy_d1"
              )
            : "Privacy Policy A&F Auto Repair LLC built the DOOS Cars app as a Commercial app. This SERVICE is provided by A&F Auto Repair LLC and is intended for use as is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at DOOS Cars unless otherwise defined in this Privacy Policy."}
        </li>
        <li>
          <p style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "INFORMATION COLLECTION AND USE",
                  "lbl_privacy_policy_info_collection_and_use"
                )
              : "INFORMATION COLLECTION AND USE"}
          </p>
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to e-mail,phone number,location. The information that we request will be retained by us and used as described in this privacy policy. The app does use third party services that may collect information used to identify you. Link to privacy policy of third party service providers used by the app •Google Play Services•FacebookLog DataWe want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information When a user booked a service request on the application (Android or iOS or website), we may keep a call record of Customer or User communication with technician in order to help they reach most relevant technician who can provide consultation for specific issues or concerns. We also keep the call recording in our system to manage disputes with Customers or Users. But we do not share these call recording with anyone else.",
                "lbl_privacy_policy_info_collection_and_use_d1"
              )
            : "For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to e-mail,phone number,location. The information that we request will be retained by us and used as described in this privacy policy. The app does use third party services that may collect information used to identify you. Link to privacy policy of third party service providers used by the app •Google Play Services•FacebookLog DataWe want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information When a user booked a service request on the application (Android or iOS or website), we may keep a call record of Customer or User communication with technician in order to help they reach most relevant technician who can provide consultation for specific issues or concerns. We also keep the call recording in our system to manage disputes with Customers or Users. But we do not share these call recording with anyone else."}
        </li>
        <li>
          <p style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "LOG DATA",
                  "lbl_privacy_policy_log_data"
                )
              : "LOG DATA"}
          </p>
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics. We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.",
                "lbl_privacy_policy_log_data_d1"
              )
            : "We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics."}
        </li>
        <li>
          <p style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "COOKIES",
                  "lbl_privacy_policy_cookies"
                )
              : "COOKIES"}
          </p>
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device’s internal memory. This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service",
                "lbl_privacy_policy_cookies_d1"
              )
            : "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device’s internal memory. This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service"}
        </li>
        <li>
          <p style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "SERVICE PROVIDERS",
                  "lbl_privacy_policy_service_providers"
                )
              : "SERVICE PROVIDERS"}
          </p>
          <p style={{ color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "We may employ third-party companies and individuals due to the following reasons:",
                  "lbl_privacy_policy_service_providers_d1"
                )
              : "We may employ third-party companies and individuals due to the following reasons:"}
          </p>
          <ul>
            <li>
              {LocalizationLabelsArray.length > 0
                ? replaceLoclizationLabel(
                    LocalizationLabelsArray,
                    "To facilitate our Service",
                    "lbl_privacy_policy_service_providers_l1"
                  )
                : "To facilitate our Service"}
            </li>
            <li>
              {LocalizationLabelsArray.length > 0
                ? replaceLoclizationLabel(
                    LocalizationLabelsArray,
                    "To provide the Service on our behalf",
                    "lbl_privacy_policy_service_providers_l2"
                  )
                : "To provide the Service on our behalf"}
            </li>
            <li>
              {LocalizationLabelsArray.length > 0
                ? replaceLoclizationLabel(
                    LocalizationLabelsArray,
                    "To perform Service-related service",
                    "lbl_privacy_policy_service_providers_l3"
                  )
                : "To perform Service-related service"}
            </li>
            <li>
              {LocalizationLabelsArray.length > 0
                ? replaceLoclizationLabel(
                    LocalizationLabelsArray,
                    "To assist us in analyzing how our Service is used",
                    "lbl_privacy_policy_service_providers_l4"
                  )
                : "To assist us in analyzing how our Service is used"}
            </li>
          </ul>
          <p style={{ color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.",
                  "lbl_privacy_policy_service_providers_l4"
                )
              : "We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose."}
          </p>
        </li>
        <li>
          <p style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "SECURITY",
                  "lbl_privacy_policy_security"
                )
              : "SECURITY"}
          </p>
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "we value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. Links to Other Sites",
                "lbl_privacy_policy_security_d1"
              )
            : "we value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. Links to Other Sites"}
        </li>
        <li>
          <p style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "LINK TO OTHER SITES",
                  "lbl_privacy_policy_link_to_other_sites"
                )
              : "LINK TO OTHER SITES"}
          </p>
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.",
                "lbl_privacy_policy_link_to_other_sites_d1"
              )
            : "This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services."}
        </li>
        <li>
          <p style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "CHILDREN’S PRIVACY",
                  "lbl_privacy_policy_childrens_privacy"
                )
              : "CHILDREN’S PRIVACY"}
          </p>
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.",
                "lbl_privacy_policy_childrens_privacy_d1"
              )
            : "These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions."}
        </li>
        <li>
          <p style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  "CHANGES TO THIS PRIVACY POLICY",
                  "lbl_privacy_policy_changes_to_this_privacy_policy"
                )
              : "CHANGES TO THIS PRIVACY POLICY"}
          </p>
          {LocalizationLabelsArray.length > 0
            ? replaceLoclizationLabel(
                LocalizationLabelsArray,
                "We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of 2020-10-11",
                "lbl_privacy_policy_changes_to_this_privacy_policy_d1"
              )
            : "We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of 2020-10-11"}
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;


//lbl_flutter_Continue with Email
//lbl_flutter_Continue as a Guest
//lbl_flutter_SERVICES
//lbl_flutter_See All
//lbl_flutter_SCHEDULE AN APPOINTMENT
//lbl_flutter_NEW PRODUCTS
//lbl_flutter_Search Car Parts...
//lbl_flutter_DELIVERED WITH CARE

//lbl_flutter_QUALITY PRODUCTS
//lbl_flutter_ONLINE SUPPORT
//lbl_flutter_OUR HAPPY CUSTOMER
//lbl_flutter_customer_review