import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetLocalizationControlsJsonDataForScreen, getLanguageCodeFromSession, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';



const SiteBreadcrumb = (props) => {
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
 useEffect(() => {
   // declare the data fetching function
   const dataOperationFunc = async () => {

     //-- Get website localization data
     let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
       GlobalEnums.Entities["Index_Home_Page"],
       null
     );
     if (
       arryRespLocalization != null &&
       arryRespLocalization != undefined &&
       arryRespLocalization.length > 0
     ) {
       await setLocalizationLabelsArray(arryRespLocalization);
     }
   };
   // call the function
   dataOperationFunc().catch(console.error);
 }, []);

    return (
      <>
        <div className="page-title-area">
          <div className="container">
            <ul>
              <li>
                <Link to={`/${getLanguageCodeFromSession()}/`}>
                  <a>
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Home",
                          "lbl_breadcrumb_home"
                        )
                      : "Home"}
                  </a>
                </Link>
              </li>
              <li>
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      props.title,
                      props.lblKey
                    )
                  : props.title}
              </li>
            </ul>
          </div>
        </div>
      </>
    );

}

export default SiteBreadcrumb;