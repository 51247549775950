import React, { Component } from "react";
import { Link } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import dynamic from "next/dynamic";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";

import Config from "../../../helpers/Config";
import { getLanguageCodeFromSession } from "../../../helpers/CommonHelper";

const OwlCarousel = dynamic(import("react-owl-carousel3"));

const options = {
  items: 1,
  loop: true,
  nav: false,
  dots: true,
  animateOut: "fadeOutUp",
  animateIn: "fadeInUp",
  smartSpeed: 750,
  autoplay: true,
  autoplayHoverPause: true,
  navText: [
    "<i class='fas fa-arrow-left'></i>",
    "<i class='fas fa-arrow-right'></i>",
  ],
};

class BannerSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      panel: true,
      bannerList: [],
      adminPanelBaseURL: Config["ADMIN_BASE_URL"],
    };
  }

  async componentDidMount() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const param = {
      requestParameters: {
        recordValueJson: "[]",
      },
    };

    //--Get product detail
    const response = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_HOME_SCREEN_BANNER"],
      null,
      param,
      headers,
      "POST",
      true
    );
    if (response != null && response.data != null) {
      const parseData = JSON.parse(response.data.data);

      parseData.forEach((banner) => {
        const lowerCaseTitle = banner.TopTitle.toLowerCase();

        if (lowerCaseTitle.includes("pink service for women")) {
          banner.CategoryId = 1;
        } else if (lowerCaseTitle.includes("recovery service")) {
          banner.CategoryId = 2;
        } else if (
          lowerCaseTitle.includes("annual car maintenance membership")
        ) {
          banner.CategoryId = 3;
        } else if (lowerCaseTitle.includes("car test")) {
          banner.CategoryId = 4;
        } else if (lowerCaseTitle.includes("garage services")) {
          banner.CategoryId = 5;
        } else if (lowerCaseTitle.includes("car spare parts")) {
          banner.CategoryId = 6;
        }
      });

      this.setState({ bannerList: parseData });
    }
    
    

    setTimeout(() => {
      this.setState({ display: true });
    }, 500);
  }

  render() {
    return (
      <>
        {this.state.display ? (
          <OwlCarousel
            className="home-slides-three owl-carousel owl-theme"
            {...options}
          >
            {this.state.bannerList
              .filter((x) => x.ThemeTypeID == 1)
              ?.map((item, idx) => (
                <div
                  key={item.BannerId}
                  className="main-banner"
                  style={{
                    backgroundImage: `url(${
                      this.state.adminPanelBaseURL + item.BannerImgUrl
                    })`,
                  }}
                >
                  <div className="d-table">
                    <div className="d-table-cell">
                      <div className="overlay-black"></div>
                      <div className="container hero-content">
                        <VisibilitySensor>
                          {({ isVisible }) => (
                            <div className="main-banner-content white-color">
                              <span
                                className={
                                  isVisible
                                    ? "text-primary subheading animated fadeInUp opacityOne"
                                    : "text-primary subheading animated fadeInDown opacityZero"
                                }
                              >
                                {item.TopTitle}
                              </span>
                              <h1
                                className={
                                  isVisible
                                    ? "main-heading animated fadeInUp opacityOne"
                                    : "main-heading animated fadeInDown opacityZero"
                                }
                              >
                                {item.MainTitle}
                              </h1>
                              <p
                                className={
                                  isVisible
                                    ? "animated fadeInUp opacityOne"
                                    : "animated fadeInDown opacityZero"
                                }
                              >
                                {item.BottomTitle}
                              </p>

                              <div className="d-flex flex-wrap gap-3 text-center justify-content-center">
                                {item.LeftButtonText != undefined &&
                                item.LeftButtonText.length > 0 ? (
                                  <>
                                    <Link
                                      // to={`/bookApoinment`}
                                      to={`/${getLanguageCodeFromSession()}/all-categories/${
                                        item.CategoryId
                                      }`} // to={`/${getLanguageCodeFromSession()}/all-categories/${
                                      //   item?.CategoryId
                                      // }`}
                                    >
                                      <a
                                        className={`btn btn-primary-2 ${
                                          isVisible
                                            ? "animated fadeInUp opacityOne"
                                            : "opacityZero"
                                        }`}
                                      >
                                        {"View Details"}
                                        {/* {item.LeftButtonText} */}
                                      </a>
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {item.RightButtonText != undefined &&
                                item.RightButtonText.length > 0 ? (
                                  <>
                                    <Link to="#">
                                      <a
                                        className={`btn btn-light ${
                                          isVisible
                                            ? "animated fadeInUp opacityOne"
                                            : "animated fadeInDown opacityZero"
                                        }`}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.props.handleBannerButtonClickUrl(
                                            item.RightButtonUrl
                                          );
                                        }}
                                      >
                                        {item.RightButtonText}
                                      </a>
                                    </Link>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          )}
                        </VisibilitySensor>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </OwlCarousel>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default BannerSlider;
